import React from 'react';
import eng from '../assets/images/eng.png'
import esp from '../assets/images/esp.png'

export function EngWave() {
    return (
        <img src={eng} alt='eng'></img>
    );
}

export function EspWave() {
    return (
        <img src={esp} alt='esp'></img>
    );
}